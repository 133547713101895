@charset "utf-8";

// https://css-tricks.com/introducing-sass-modules/
@use './scss/abstracts/utilities' as *;

// https://bulma.io/documentation/customize/with-node-sass/
$green: #495e37;
$beige-light: #f7f5ee;
$beige-lighter: #f7f5ee;

$grey-dark: $green;
$grey-light: $beige-light;

$body-background-color: $beige-lighter;

@import "~bulma/bulma.sass";

// import components
@import "./scss/components/card.scss";
@import "./scss/components/form.scss";

:root {
//  --yellow: #fee900;
//  --font-color-primary: #495e37;
//  --font-color-secondary: #212e1b;
//  --bg-bright: #f7f5ee;
//  --bg-dark: #838070;
}


.hidden {
  display: none;
}
.visible {
  display:block;
}

.controlbox {
  display: none;
}

.title {
  color: $grey-dark;
}

.App {
  text-align: center;
  color: $grey-dark;
}

.app-logo {
  width: 200px;
  height: auto;
}

.certificate-box {
  position: relative;
  padding: 0;
  aspect-ratio: 0.70711356;
  img {
    margin-bottom: -0.4rem;
  }
}

.certificate-fullname {
  position: absolute;
  //top: 52.4%;
  top: 50.8%;
  left: 30%;
}

.App {
  max-width: 1000px;
  margin: 0 auto;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}



.App-header {
  background-color: white;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.call-to-action {
  margin: 1rem;
}
.form {
  margin-bottom: 2rem;
}

.intro {
  margin-top: 1rem;
}

.default-field {
  margin-left: 1.2rem;
}
.congrats {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

@media screen and (min-width: 64em) {
  main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    row-gap: 1em;
    margin: 1rem 3rem;
  }
  .header {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }
  .preview {
    grid-column: 1 / 2;
    grid-row: 2 / 2;
  }
  .form {
    grid-column: 2 / 3;
    grid-row: 2 / 2;
  }
}

header {
  margin-top: 1rem;
  @media screen and (min-width: 64em) {
    margin-top: 0;
    a {
      float: left;
      margin-left: -0.85rem;
    }
  }
}

footer {
  font-size: 0.9rem;
  a {
    color: $grey-dark;
    text-decoration: underline;
  }
}

.field.inputbox {
  margin-bottom: 0;
}
