// Custom breakpoints start
// https://cpcwood.com/code-snippets/10-mobile-first-responsive-design-breakpoints-sass-mixin
$mybreakpoints: (
  'small' : ( max-width: 766px ),
  'medium' : ( min-width: 767px ),
  'large': ( min-width: 1024px ),
  'xlarge': ( min-width: 1300px ),
  'xxlarge': ( min-width: 2500px )
);

@mixin respond-to($name) {
  @if map-has-key($mybreakpoints, $name) {
    @media #{inspect(map-get($mybreakpoints, $name))} {
      @content;
    }
  }
  @else {
    @error '@mixin respond-to: `#{$name}` is not a key in breakpoints.' +
    ' Available breakpoints are: `#{$mybreakpoints}`';
  }
}
// Custom breakpoints end
// Alternatives
// https://rwd.is/develop/getting-started-with-sass-and-breakpoint-mixin/index.html
// https://get.foundation/sites/docs/media-queries.html

// Flexbox utilitites
.align-center {
  display: flex;
  justify-content: center;
}

// Boxed
.boxed,
.container-fluid.boxed {
  @include respond-to(xlarge) {
    padding: 0 3rem;
  }
}

// Visibility classes
// https://medium.com/codyhouse/a-new-approach-to-visibility-utility-classes-based-on-css-variables-eb1e2a01fa3b
// https://css-tricks.com/attribute-selectors/
[class*="show-for-"] {
  display: none;
}

@include respond-to(small) {
  .show-for-small-only {
    display: block;
  }
}
@include respond-to(medium) {
  .hide-for-medium {
    display: none;
  }
  .show-for-small-only {
    display: none;
  }
  .show-for-medium {
    display: block;
  }
}

@include respond-to(large) {
  .show-for-large-flex {
    display: flex;
  }
}
// https://css-tricks.com/inclusively-hidden/
// Hiding class, making content visible only to screen readers but not visually
// "sr" meaning "screen-reader"
// sr-only -> visually-hidden
.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
